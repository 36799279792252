import { CanActivateFn } from '@angular/router';
import { HttpRefreshTokenService } from '@core/http/services/http-refresh-token.service';
import { NavigatorService } from '@core/routing/routes/services/navigator.service';
import { inject } from '@angular/core';
import { Route } from '@core/routing/routes/enums/route';
import { catchError, of, switchMap, throwError } from 'rxjs';

export const RefreshGuard: CanActivateFn = () => {
  const refreshToken = inject(HttpRefreshTokenService);
  const navigator = inject(NavigatorService);

  return refreshToken.refresh().pipe(
    catchError(error => {
      navigator.navigateByRouterLink(provider =>
        provider.routerLink(Route.SignOut)
      );

      return throwError(() => error);
    }),
    switchMap(() => of(true))
  );
};
