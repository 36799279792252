import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { providePrimengTranslationsConfig } from '@core/translations/config/provide-primeng-translations-config';
import { provideRouterConfig } from '@core/routing/config/provide-router-config';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideUserContext } from '@core/auth/config/provide-user-context';
import { httpTokenInterceptor } from '@core/http/interceptors/http-token.interceptor';
import { httpRefreshTokenInterceptor } from '@core/http/interceptors/http-refresh-token.interceptor';
import { HttpLoadingInterceptor } from '@core/http/interceptors/http-loading.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouterConfig(),
    provideHttpClient(
      withInterceptors([
        httpTokenInterceptor,
        httpRefreshTokenInterceptor,
        HttpLoadingInterceptor,
      ])
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimations(),
    providePrimengTranslationsConfig(),
    provideUserContext(),
  ],
};
