import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from '../../../app.routes';
import { EnvironmentProviders } from '@angular/core';

export const provideRouterConfig = (): EnvironmentProviders => {
  return provideRouter(
    routes,
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
    })
  );
};
