import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DefaultConfirmationDirective } from '@shared/confirmation/directives/default-confirmation.directive';
import { ButtonOutlinedDirective } from '@shared/button/directives/button-outlined.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationService } from '@shared/confirmation/services/confirmation.service';
import { ConfirmationService as PrimengConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    DefaultConfirmationDirective,
    ButtonOutlinedDirective,
  ],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss',
  providers: [PrimengConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent implements OnInit {
  acceptLabel = '';
  rejectLabel = '';

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly confirmation: ConfirmationService,
    private readonly primengConfirmation: PrimengConfirmationService
  ) {}

  ngOnInit(): void {
    this.listenToConfirmationShow();
  }

  private listenToConfirmationShow(): void {
    this.confirmation.show$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: confirmation => {
          const { acceptLabel, rejectLabel, accept, reject, title, message } =
            confirmation;

          this.acceptLabel = acceptLabel;
          this.rejectLabel = rejectLabel;

          this.primengConfirmation.confirm({
            header: title,
            message: message,
            accept: accept,
            reject: reject,
          });
        },
      });
  }
}
