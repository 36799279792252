import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { HttpRequestHeaderService } from '@core/http/services/http-request-header.service';
import { isSkippedByAuth } from '@core/http/tokens/skip-by-auth-interceptor';
import { catchError, switchMap, throwError } from 'rxjs';

export const httpTokenInterceptor: HttpInterceptorFn = (request, next) => {
  const requestHeader = inject(HttpRequestHeaderService);

  if (isSkippedByAuth(request)) {
    return next(request);
  }

  return requestHeader.appendAuthorizationHeader(request).pipe(
    switchMap(request => next(request)),
    catchError(error => throwError(() => error))
  );
};
