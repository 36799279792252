import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Toast } from '@shared/toast/models/toast';
import { ToastSeverity } from '@shared/toast/types/toast-severity';
import { isNil } from '@core/utils/nil/is-nil';
type Severity = 'success' | 'warn' | 'error' | 'info';

@Injectable({
  providedIn: 'root',
})
export class ToastToPrimengMessageConverter {
  convert(toast: Toast): Message {
    return {
      detail: toast.message,
      summary: toast.title,
      severity: this.severity(toast.severity),
      sticky: toast.immortal,
      life: toast.life,
    };
  }

  private severity(severity: ToastSeverity): Severity {
    const entries = new Map<ToastSeverity, Severity>([
      ['success', 'success'],
      ['info', 'info'],
      ['warning', 'warn'],
      ['error', 'error'],
    ]);

    const entry = entries.get(severity);

    if (isNil(entry)) {
      throw new Error(`The severity entry for "${severity}" not found.`);
    }

    return entry;
  }
}
