import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { NavigatorService } from '@core/routing/routes/services/navigator.service';
import { HttpRequestHeaderService } from '@core/http/services/http-request-header.service';
import { HttpRefreshTokenService } from '@core/http/services/http-refresh-token.service';
import { isSkippedByRefreshToken } from '@core/http/tokens/skip-by-refresh-token-interceptor';
import { catchError, filter, switchMap, throwError } from 'rxjs';
import { isUnauthorized } from '@core/http/utils/is-unauthorized';
import { once } from '@core/utils/rxjs/once';
import { Route } from '@core/routing/routes/enums/route';

export const httpRefreshTokenInterceptor: HttpInterceptorFn = (
  request,
  next
) => {
  const navigator = inject(NavigatorService);
  const requestHeader = inject(HttpRequestHeaderService);
  const refreshToken = inject(HttpRefreshTokenService);

  if (isSkippedByRefreshToken(request)) {
    return next(request);
  }

  return next(request).pipe(
    catchError(error => {
      if (!isUnauthorized(error)) {
        return throwError(() => error);
      }

      return refreshToken.refreshing$.pipe(
        once(),
        switchMap(refreshing => {
          if (refreshing) {
            return refreshToken.refreshed$.pipe(
              filter(Boolean),
              once(),
              switchMap(() =>
                requestHeader
                  .appendAuthorizationHeader(request)
                  .pipe(switchMap(request => next(request)))
              )
            );
          }

          return refreshToken.refresh().pipe(
            switchMap(() => requestHeader.appendAuthorizationHeader(request)),
            switchMap(request => next(request)),
            catchError(error => {
              navigator.navigateByRouterLink(provider =>
                provider.routerLink(Route.SignOut)
              );

              return throwError(() => error);
            })
          );
        })
      );
    })
  );
};
