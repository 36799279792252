import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastToPrimengMessageConverter } from '@shared/toast/converters/toast-to-primeng-message.converter';
import { MessageService } from 'primeng/api';
import { ToastService } from '@shared/toast/services/toast.service';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [ToastModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly message: MessageService,
    private readonly toast: ToastService,
    private readonly converter: ToastToPrimengMessageConverter
  ) {}

  ngOnInit(): void {
    this.listenToToastShow();
  }

  private listenToToastShow(): void {
    this.toast.show$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(toast => {
        this.message.add(this.converter.convert(toast));
      });
  }
}
