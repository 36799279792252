import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { HttpLoadingService } from '@core/http/services/http-loading.service';
import { isSkippedByLoading } from '@core/http/tokens/skip-by-loading-interceptor';
import { catchError, finalize, throwError } from 'rxjs';

export const HttpLoadingInterceptor: HttpInterceptorFn = (request, next) => {
  const loading = inject(HttpLoadingService);

  if (isSkippedByLoading(request)) {
    return next(request);
  }

  const { url } = request;

  loading.loadingFor(url);

  return next(request).pipe(
    finalize(() => loading.unloadingFor(url)),
    catchError(error => {
      loading.unloadingFor(url);

      return throwError(() => error);
    })
  );
};
