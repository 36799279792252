import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthService } from '@core/auth/services/auth.service';
import { blank } from '@core/utils/rxjs/blank';

@Injectable({
  providedIn: 'root',
})
export class HttpRefreshTokenService {
  private readonly refreshedSource$ = new BehaviorSubject<boolean>(false);
  private readonly refreshingSource$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly auth: AuthService) {}

  get refreshing$(): Observable<boolean> {
    return this.refreshingSource$.asObservable();
  }

  get refreshed$(): Observable<boolean> {
    return this.refreshedSource$.asObservable();
  }

  refresh(): Observable<void> {
    this.refreshingSource$.next(true);
    this.refreshedSource$.next(false);

    return this.auth.refreshToken().pipe(
      tap({
        next: () => {
          this.refreshingSource$.next(false);
          this.refreshedSource$.next(true);
        },
        error: () => {
          this.refreshingSource$.next(false);
          this.refreshedSource$.next(false);
        },
      }),
      blank()
    );
  }
}
