import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { AuthService } from '@core/auth/services/auth.service';
import { blank } from '@core/utils/rxjs/blank';

export const provideUserContext = (): Provider => {
  return {
    provide: APP_INITIALIZER,
    useFactory: (): (() => Observable<void>) => {
      const auth = inject(AuthService);

      return () =>
        auth.fetchUserContext().pipe(
          catchError(() => of(void 0)),
          blank()
        );
    },
    multi: true,
  };
};
