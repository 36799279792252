import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { PrimengTranslationProvider } from '../providers/primeng-translation.provider';
import { PrimeNGConfig } from 'primeng/api';

export const providePrimengTranslationsConfig = (): Provider => {
  return {
    provide: APP_INITIALIZER,
    useFactory: (): (() => void) => {
      const provider = inject(PrimengTranslationProvider);
      const config = inject(PrimeNGConfig);

      return () => config.setTranslation(provider.getTranslation());
    },
    multi: true,
  };
};
