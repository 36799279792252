import { Injectable } from '@angular/core';
import { Route } from '@core/routing/routes/enums/route';
import { Observable, of } from 'rxjs';
import { isNil } from '@core/utils/nil/is-nil';

@Injectable({
  providedIn: 'root',
})
export class RouteTitleProvider {
  private readonly entries = new Map<Route, string>([
    [Route.SignIn, 'Zaloguj się'],
    [Route.ResetPassword, 'Zresetuj hasło'],
    [Route.RecoverPassword, 'Odzyskaj hasło'],
    [Route.Assignments, 'Zlecenia'],
    [Route.AssignmentDetails, 'Szczegóły zlecenia'],
    [Route.AssignmentCreate, 'Utwórz zlecenie'],
    [Route.AssignmentUpdate, 'Edytuj zlecenie'],
    [Route.AssignmentsForCustomer, 'Zlecenia'],
    [Route.Customers, 'Zleceniodawcy'],
    [Route.CustomerUpdate, 'Edytuj zleceniodawcę'],
    [Route.DictionaryExaminations, 'Słowniki - Badania'],
    [Route.DictionaryAntibiotics, 'Słowniki - Antybiotyki'],
    [Route.DictionaryMicrobes, 'Słowniki - Drobnoustroje'],
    [Route.DictionaryAntibiograms, 'Słowniki - Antybiogramy'],
    [Route.DictionarySampleTypes, 'Słowniki - Typy próbek'],
    [Route.Samples, 'Próbki'],
    [Route.Reminders, 'Przypomnienia'],
    [Route.Notifications, 'Powiadomienia'],
    [Route.NotificationDetails, 'Szczegóły powiadomienia'],
    [Route.Sequences, 'Baza sekwencji'],
  ]);

  entry(route: Route): Observable<string> {
    const entry = this.entries.get(route);

    if (isNil(entry)) {
      const key = Route[route];

      throw new Error(`The route title "${key}" not found.`);
    }

    return of(entry);
  }
}
