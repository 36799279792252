import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
} from '@angular/core';
import { debounceTime, Observable } from 'rxjs';
import { isNil } from '@core/utils/nil/is-nil';
import { ConfirmationService } from '@shared/confirmation/services/confirmation.service';
import { Confirmation } from '@shared/confirmation/models/confirmation';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appDefaultConfirmation]',
  standalone: true,
})
export class DefaultConfirmationDirective implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly elementRef: ElementRef,
    private readonly confirmation: ConfirmationService
  ) {}

  ngAfterViewInit(): void {
    this.listenToOnShow();
  }

  private listenToOnShow(): void {
    this.onShowSource()
      .pipe(debounceTime(0), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const button: HTMLButtonElement =
          this.elementRef.nativeElement.querySelector(
            '.p-confirm-dialog .p-dialog-header-close'
          );

        if (isNil(button)) {
          return;
        }

        button.focus();
      });
  }

  private onShowSource(): Observable<Confirmation> {
    return this.confirmation.show$;
  }
}
