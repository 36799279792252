import { Injectable } from '@angular/core';
import { RouterLinkProvider } from '@core/routing/routes/providers/router-link.provider';
import { Params, Router, UrlTree } from '@angular/router';
import { Nillable } from '@core/utils/nil/nillable';
import { Route } from '@core/routing/routes/enums/route';

@Injectable({
  providedIn: 'root',
})
export class UrlTreeProvider {
  constructor(
    private readonly routerLinkProvider: RouterLinkProvider,
    private readonly router: Router
  ) {}

  urlTree(path: Route, params?: Nillable<Params>): UrlTree {
    return this.router.createUrlTree(
      this.routerLinkProvider.routerLink(path, params)
    );
  }
}
