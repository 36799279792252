<p-confirmDialog
  appDefaultConfirmation
  #confirmation
  [style]="{ width: '30rem' }">
  <ng-template pTemplate="footer">
    <div class="row g-3 rows-col-2">
      <div class="col">
        <button
          pButton
          appButtonOutlined
          type="button"
          class="w-100"
          [label]="rejectLabel"
          (click)="confirmation.reject()"></button>
      </div>

      <div class="col">
        <button
          pButton
          type="button"
          class="w-100"
          [label]="acceptLabel"
          (click)="confirmation.accept()"></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
