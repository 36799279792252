import { CanActivateFn } from '@angular/router';
import { UrlTreeProvider } from '@core/routing/routes/providers/url-tree.provider';
import { inject } from '@angular/core';
import { Route } from '@core/routing/routes/enums/route';
import { AuthService } from '@core/auth/services/auth.service';
import { once } from '@core/utils/rxjs/once';
import { map } from 'rxjs';

export const onlyLoggedGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const urlTreeProvider = inject(UrlTreeProvider);
  const signOutUrlTree = urlTreeProvider.urlTree(Route.SignOut);

  return auth.isLoggedIn().pipe(
    once(),
    map(isLogged => isLogged || signOutUrlTree)
  );
};
