import { Routes } from '@angular/router';
import { RouteFragment } from '@core/routing/routes/enums/route-fragment';
import { onlyLoggedGuard } from '@core/auth/guards/only-logged.guard';
import { onlyNotLoggedGuard } from '@core/auth/guards/only-not-logged.guard';
import { RouteParam } from '@core/routing/routes/enums/route-param';
import { resolveTitleFor } from '@core/routing/titles/utils/resolve-title-for';
import { Route } from '@core/routing/routes/enums/route';
import { RefreshGuard } from '@core/auth/guards/refresh.guard';
import { EquipmentResolver } from '@features/equipment/resolvers/equipment.resolver';
import { RouteDataKey } from '@core/routing/routes/enums/route-data-key';
import { EquipmentReportResolver } from '@features/equipment/resolvers/equipment-report.resolver';

export const routes: Routes = [
  {
    path: RouteFragment.Empty,
    canActivate: [onlyLoggedGuard, RefreshGuard],
    loadComponent: () =>
      import(
        '@core/layouts/components/default-content-layout/default-content-layout.component'
      ).then(c => c.DefaultContentLayoutComponent),
    children: [
      {
        path: RouteFragment.Assignments,
        children: [
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            title: resolveTitleFor(Route.Assignments),
            loadComponent: () =>
              import(
                '@features/assignment/pages/assignment-list-page/assignment-list-page.component'
              ).then(c => c.AssignmentListPageComponent),
          },
          {
            path: RouteFragment.Create,
            title: resolveTitleFor(Route.AssignmentCreate),
            loadComponent: () =>
              import(
                '@features/assignment/pages/assignment-create-page/assignment-create-page.component'
              ).then(c => c.AssignmentCreatePageComponent),
          },
          {
            path: `:${RouteParam.AssignmentId}`,
            children: [
              {
                path: RouteFragment.Empty,
                title: resolveTitleFor(Route.AssignmentDetails),
                loadComponent: () =>
                  import(
                    '@features/assignment/pages/assignment-details-page/assignment-details-page.component'
                  ).then(c => c.AssignmentDetailsPageComponent),
              },
              {
                path: RouteFragment.Update,
                title: resolveTitleFor(Route.AssignmentUpdate),
                loadComponent: () =>
                  import(
                    '@features/assignment/pages/assignment-update-page/assignment-update-page.component'
                  ).then(c => c.AssignmentUpdatePageComponent),
              },
              {
                path: RouteFragment.ExaminationResults,
                children: [
                  {
                    path: RouteFragment.Create,
                    title: resolveTitleFor(
                      Route.AssignmentExaminationResultsCreate
                    ),
                    loadComponent: () =>
                      import(
                        '@features/assignment/pages/assignment-examination-result-create-page/assignment-examination-result-create-page.component'
                      ).then(
                        c => c.AssignmentExaminationResultCreatePageComponent
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: `:${RouteParam.CustomerId}`,
            children: [
              {
                path: RouteFragment.Customer,
                title: resolveTitleFor(Route.AssignmentsForCustomer),
                loadComponent: () =>
                  import(
                    '@features/assignment/pages/assignment-list-page/assignment-list-page.component'
                  ).then(c => c.AssignmentListPageComponent),
              },
            ],
          },
          {
            path: RouteFragment.Wildcard,
            redirectTo: RouteFragment.Empty,
          },
        ],
      },
      {
        path: RouteFragment.Customers,
        children: [
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            title: resolveTitleFor(Route.Customers),
            loadComponent: () =>
              import(
                '@features/customer/pages/customer-list-page/customer-list-page.component'
              ).then(c => c.CustomerListPageComponent),
          },
          {
            path: `:${RouteParam.CustomerId}`,
            children: [
              {
                path: RouteFragment.Update,
                title: resolveTitleFor(Route.CustomerUpdate),
                loadComponent: () =>
                  import(
                    '@features/customer/pages/customer-update-page/customer-update-page.component'
                  ).then(c => c.CustomerUpdatePageComponent),
              },
            ],
          },
          {
            path: RouteFragment.Wildcard,
            redirectTo: RouteFragment.Empty,
          },
        ],
      },
      {
        path: RouteFragment.Dictionary,
        loadComponent: () =>
          import(
            '@features/dictionary/pages/dictionary-page/dictionary-page.component'
          ).then(c => c.DictionaryPageComponent),
        children: [
          {
            path: RouteFragment.Examinations,
            title: resolveTitleFor(Route.DictionaryExaminations),
            loadComponent: () =>
              import(
                '@features/dictionary/pages/dictionary-examination-list-page/dictionary-examination-list-page.component'
              ).then(c => c.DictionaryExaminationListPageComponent),
          },
          {
            path: RouteFragment.Antibiotics,
            title: resolveTitleFor(Route.DictionaryAntibiotics),
            loadComponent: () =>
              import(
                '@features/dictionary/pages/dictionary-antibiotic-list-page/dictionary-antibiotic-list-page.component'
              ).then(c => c.DictionaryAntibioticListPageComponent),
          },
          {
            path: RouteFragment.Microbes,
            title: resolveTitleFor(Route.DictionaryMicrobes),
            loadComponent: () =>
              import(
                '@features/dictionary/pages/dictionary-microbe-list-page/dictionary-microbe-list-page.component'
              ).then(c => c.DictionaryMicrobeListPageComponent),
          },
          {
            path: RouteFragment.Antibiograms,
            title: resolveTitleFor(Route.DictionaryAntibiograms),
            loadComponent: () =>
              import(
                '@features/dictionary/pages/dictionary-antibiogram-list-page/dictionary-antibiogram-list-page.component'
              ).then(c => c.DictionaryAntibiogramListPageComponent),
          },
          {
            path: RouteFragment.SampleTypes,
            title: resolveTitleFor(Route.DictionarySampleTypes),
            loadComponent: () =>
              import(
                '@features/dictionary/pages/dictionary-sample-type-list-page/dictionary-sample-type-list-page.component'
              ).then(c => c.DictionarySampleTypeListPageComponent),
          },
          {
            path: RouteFragment.EquipmentCategories,
            title: resolveTitleFor(Route.DictionaryEquipmentCategories),
            loadComponent: () =>
              import(
                '@features/dictionary/pages/dictionary-equipment-category-list-page/dictionary-equipment-category-list-page.component'
              ).then(c => c.DictionaryEquipmentCategoryListPageComponent),
          },
          {
            path: RouteFragment.EquipmentParameters,
            title: resolveTitleFor(Route.DictionaryEquipmentParameters),
            loadComponent: () =>
              import(
                '@features/dictionary/pages/dictionary-equipment-parameter-list-page/dictionary-equipment-parameter-list-page.component'
              ).then(c => c.DictionaryEquipmentParameterListPageComponent),
          },
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            redirectTo: RouteFragment.Examinations,
          },
        ],
      },
      {
        path: RouteFragment.Samples,
        children: [
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            title: resolveTitleFor(Route.Samples),
            loadComponent: () =>
              import(
                '@features/sample/pages/sample-list-page/sample-list-page.component'
              ).then(c => c.SampleListPageComponent),
          },
          {
            path: RouteFragment.Wildcard,
            redirectTo: RouteFragment.Empty,
          },
        ],
      },
      {
        path: RouteFragment.Reminders,
        children: [
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            title: resolveTitleFor(Route.Reminders),
            loadComponent: () =>
              import(
                '@features/reminder/pages/reminder-list-page/reminder-list-page.component'
              ).then(c => c.ReminderListPageComponent),
          },
          {
            path: RouteFragment.Wildcard,
            redirectTo: RouteFragment.Empty,
          },
        ],
      },
      {
        path: RouteFragment.Notifications,
        children: [
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            title: resolveTitleFor(Route.Notifications),
            loadComponent: () =>
              import(
                '@features/notification/pages/notification-list-page/notification-list-page.component'
              ).then(c => c.NotificationListPageComponent),
          },
          {
            path: RouteFragment.Wildcard,
            redirectTo: RouteFragment.Empty,
          },
        ],
      },
      {
        path: RouteFragment.Sequences,
        children: [
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            title: resolveTitleFor(Route.Sequences),
            loadComponent: () =>
              import(
                '@features/sequence/pages/sequence-list-page/sequence-list-page.component'
              ).then(c => c.SequenceListPageComponent),
          },
          {
            path: RouteFragment.Wildcard,
            redirectTo: RouteFragment.Empty,
          },
        ],
      },
      {
        path: RouteFragment.Equipment,
        children: [
          {
            path: RouteFragment.Empty,
            pathMatch: 'full',
            title: resolveTitleFor(Route.Equipment),
            loadComponent: () =>
              import(
                '@features/equipment/pages/equipment-list-page/equipment-list-page.component'
              ).then(c => c.EquipmentListPageComponent),
          },
          {
            path: `:${RouteParam.EquipmentId}`,
            resolve: {
              [RouteDataKey.Details]: EquipmentResolver,
            },
            children: [
              {
                path: RouteFragment.Empty,
                loadComponent: () =>
                  import(
                    '@features/equipment/pages/equipment-details-content-page/equipment-details-content-page.component'
                  ).then(c => c.EquipmentDetailsContentPageComponent),
                children: [
                  {
                    path: RouteFragment.Empty,
                    title: resolveTitleFor(Route.EquipmentDetails),
                    loadComponent: () =>
                      import(
                        '@features/equipment/pages/equipment-details-page/equipment-details-page.component'
                      ).then(c => c.EquipmentDetailsPageComponent),
                  },
                ],
              },
              {
                path: RouteFragment.Reports,
                loadComponent: () =>
                  import(
                    '@features/equipment/pages/equipment-details-content-page/equipment-details-content-page.component'
                  ).then(c => c.EquipmentDetailsContentPageComponent),
                children: [
                  {
                    path: RouteFragment.Empty,
                    title: resolveTitleFor(Route.EquipmentReports),
                    loadComponent: () =>
                      import(
                        '@features/equipment/pages/equipment-report-list-page/equipment-report-list-page.component'
                      ).then(c => c.EquipmentReportListPageComponent),
                  },
                  {
                    path: `:${RouteParam.ReportId}`,
                    resolve: {
                      [RouteDataKey.Details]: EquipmentReportResolver,
                    },
                    title: resolveTitleFor(Route.EquipmentReportDetails),
                    loadComponent: () =>
                      import(
                        '@features/equipment/pages/equipment-report-details-page/equipment-report-details-page.component'
                      ).then(c => c.EquipmentReportDetailsPageComponent),
                  },
                ],
              },
              {
                path: RouteFragment.Wildcard,
                redirectTo: RouteFragment.Empty,
              },
            ],
          },
          {
            path: RouteFragment.Wildcard,
            redirectTo: RouteFragment.Empty,
          },
        ],
      },
      {
        path: RouteFragment.Empty,
        pathMatch: 'full',
        redirectTo: RouteFragment.Assignments,
      },
    ],
  },
  {
    path: RouteFragment.Auth,
    loadComponent: () =>
      import(
        '@core/layouts/components/auth-content-layout/auth-content-layout.component'
      ).then(c => c.AuthContentLayoutComponent),
    children: [
      {
        path: `${RouteFragment.ResetPassword}/:${RouteParam.ActivationToken}`,
        title: resolveTitleFor(Route.ResetPassword),
        canActivate: [onlyNotLoggedGuard],
        loadComponent: () =>
          import(
            '@features/auth/pages/reset-password-page/reset-password-page.component'
          ).then(c => c.ResetPasswordPageComponent),
      },
      {
        path: RouteFragment.SignOut,
        loadComponent: () =>
          import(
            '@features/auth/pages/sign-out-page/sign-out-page.component'
          ).then(c => c.SignOutPageComponent),
      },
      {
        path: RouteFragment.SignIn,
        canActivate: [onlyNotLoggedGuard],
        title: resolveTitleFor(Route.SignIn),
        loadComponent: () =>
          import(
            '@features/auth/pages/sign-in-page/sign-in-page.component'
          ).then(c => c.SignInPageComponent),
      },
      {
        path: RouteFragment.RecoverPassword,
        canActivate: [onlyNotLoggedGuard],
        title: resolveTitleFor(Route.RecoverPassword),
        loadComponent: () =>
          import(
            '@features/auth/pages/recover-password-page/recover-password-page.component'
          ).then(c => c.RecoverPasswordPageComponent),
      },
      {
        path: RouteFragment.Wildcard,
        redirectTo: RouteFragment.SignIn,
      },
    ],
  },
  {
    path: RouteFragment.NotFound,
    loadComponent: () =>
      import(
        '@features/application-state/pages/not-found-page/not-found-page.component'
      ).then(c => c.NotFoundPageComponent),
  },
  {
    path: RouteFragment.Wildcard,
    redirectTo: RouteFragment.Empty,
  },
];
